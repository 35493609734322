<template>
  <div>
    <el-row>
      <el-col :span="6">
        <span class="dash-value dash-num-blue" style="margin-top: 20px">{{total}}</span>&nbsp;
        <span class="dash-title">{{$t('listGroup.group')}}</span>&nbsp;
      </el-col>
      <el-col :span="18">
        <div class="searchBar">
          <slot><el-button type="primary" size="small" round icon="el-icon-plus" @click="addRole" v-if="accountInfo.role <= 3">{{$t('listGroup.add')}}</el-button></slot>
        </div>
      </el-col>
    </el-row>
    <el-table :data="items" style="width: 100%;padding: 10px 10px 10px 10px;">
      <el-table-column prop="groupCode" :label="$t('listGroup.code')" min-width="90px"></el-table-column>
      <el-table-column prop="groupName" :label="$t('listGroup.name')" min-width="160px"></el-table-column>
      <el-table-column prop="bimsBlcbid" label="BIMSの請求先コード" min-width="160px"></el-table-column>
      <el-table-column prop="scndAgencyNm" label="DIPFS用二次店名" min-width="140px"></el-table-column>
      <el-table-column prop="ppCbsic1Nm" label="PP担当者" min-width="110px"></el-table-column>
      <el-table-column :label="$t('listGroup.process')" min-width="160px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row.groupId)" plain round>{{$t('listUser.edit')}}</el-button>
          <el-button size="mini" type="danger" @click="confirmDelete(scope.row.groupId)" plain round v-if="accountInfo.role <= 3">{{$t('listGroup.remove')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageIndex"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { listGroup,removeGroup} from "@/api/admin";
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";

const defaultSearchParams = {
  pageIndex: 1,
  pageSize: 10
};
export default {
  name: "ListRole",
  data: () => {
    return {
      searchParams: { ...defaultSearchParams },
      totalCount: 0,
      items: []
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    refreshData() {
      listGroup({
        groupId: this.accountInfo.groupId,
        ...this.searchParams
      }).then(response => {
        this.total = response.data.totalCount;
        this.items = response.data.items;
      });
    },
    handleSizeChange(val){
      this.searchParams = { ...this.searchParams, pageSize: val };
      this.refreshData();
    },
    handleCurrentChange(val) {
      this.searchParams = { ...this.searchParams, pageIndex: val };
      this.refreshData();
    },
    addRole(){
      this.$router.push(ROUTER_PATH.ADD_GROUP);
    },
    handleUpdate(uid){
      this.$router.push({path:ROUTER_PATH.UPDATE_GROUP, query:{id:uid}});
    },
    confirmDelete(rid) {
        this.$confirm(this.$t('listGroup.confirm'), this.$t('common.popup'), {
          confirmButtonText: this.$t('common.ok'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          removeGroup({
            groupId: rid
          }).then(response => {
            this.$message({
              type: 'success',
              message: response.message
            });
            this.refreshData();
          });
          
        }).catch(() => {
                  
        });
      }
  }
};
</script>
<style>
  .searchBar {
    margin: 0px 0px 10px 0px;
    /*padding: 10px 10px ;*/
    /*background-color: #f7f7f7;*/
    text-align: right;
  }
  </style>
